import React, {useEffect} from 'react'
import {Nav, Navbar, NavItem} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import style from './Layout.scss'

import {logout} from "../util/authentication";
import {me} from "../api";
import {useHistory} from "react-router";
import {isNoNeedLogins} from "../routes";

const Layout = ({children}) => {
  const history = useHistory()
  const noNeedLogin = isNoNeedLogins(history.location.pathname)

  useEffect(() => {
    (async () => {
      if (noNeedLogin) {
        return
      }
      const iam = await me()
      if (!iam) {
        doLogout()
      }
    })()
  }, [noNeedLogin])

  const doLogout = (e) => {
    logout()
    document.location.href = '/login'
  }

  return (
    <>
      <Navbar className={`me-auto ${style.topBar}`}>
        <Nav className="me-auto">
          <NavItem className={style.brand}>名刺プラス</NavItem>
        </Nav>
        <Nav className="justify-content-end">
          {
            !noNeedLogin &&
            <div className={`btn-group ${style.menuPanel}`}>
              <button data-toggle="dropdown"
                      className={style.dropDownButton}
                      aria-haspopup="true"
                      aria-expanded="false">
                <span className={`navbar-toggler-icon ${style.menuButton}`}/>
              </button>
                <div className={`dropdown-menu dropdown-menu-right ${style.dropDownPanel}`}>
                  <button className="dropdown-item" type="button" onClick={doLogout}>ログアウト</button>
                </div>
            </div>
          }
        </Nav>
      </Navbar>
      <div className="contents">
        <section className={style.pageContainer}>
          <main>{children}</main>
        </section>
      </div>
    </>
  )
}

export default Layout
