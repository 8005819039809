import React from "react";
import style from './Top.scss'
import Layout from "../layout/Layout";

const Top = () => {
  return (
    <Layout>
      <>
        <div className={style.menuPanel}>
          <div className={style.subtitle}>
            <h1>ご契約内容</h1>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default Top;
