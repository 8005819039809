import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '../pages/Login';
import Top from '../pages/Top';

export const isNoNeedLogins = (location) => {
  return ['/login'].filter((l) => { return l === location }).length > 0
}

const routes = (
  <div>
    <Switch>
      <Route exact path="/" component={Top} />
      <Route path="/login" component={Login} />
    </Switch>
  </div>
)

export default routes;
